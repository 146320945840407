import "./App.scss";
import Footer from "./components/footer";
import Header from "./components/header";
import Section1 from "./components/section1";
import Section2 from "./components/section2";
import Section3 from "./components/section3";
import Section4 from "./components/section4";
import VideoSection from "./components/video-section";

function App() {
  return (
    <>
      <Header />
      <VideoSection />
      <main className="bg-dark">
        <Section1 />
        <Section2 />
        <Section3 />
        <Section4 />
      </main>
      <Footer />
    </>
  );
}

export default App;
