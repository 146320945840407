import React, { useEffect, useRef } from "react";
import "./style.scss";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Container, Row } from "react-bootstrap";
import Image from "../../assets/images/Phone.svg";
gsap.registerPlugin(ScrollTrigger);

function VideoSection() {
  const newRef = useRef(null);
  const imgRef = useRef(null);
  const titleRef = useRef(null);
  const subtextRef = useRef(null);
  const buttonRef = useRef(null);
  useEffect(() => {
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: newRef.current,
        start: "top top+=50", // Start animation after scrolling 100 pixels
        end: "+=200",
        scrub: 1,
      },
    });

    tl.fromTo(
      imgRef.current,
      { y: 0, opacity: 1 }, // Initially visible
      { y: -50, opacity: 1, duration: 1, ease: "power3.out" } // Slight upward movement
    )
      .fromTo(
        titleRef.current,
        { y: 0, opacity: 1 },
        { y: -50, opacity: 1, duration: 1, ease: "power3.out" },
        "-=0.8"
      )
      .fromTo(
        subtextRef.current,
        { y: 0, opacity: 1 },
        { y: -40, opacity: 1, duration: 1, ease: "power3.out" },
        "-=0.8"
      )
      .fromTo(
        buttonRef.current,
        { y: 0, opacity: 1 },
        { y: -15, opacity: 1, duration: 1, ease: "power3.out" },
        "-=0.8"
      );
  }, []);
  return (
    <section className="section-video">
      <video
        loop
        autoPlay={true}
        playsInline
        muted
        className="section-video-play align-bottom"
      >
        <source type="video/mp4" src="/videosection.mp4" />
      </video>
      <Container>
        <div className="section-video-bg">
          <div className="text-white"> Making Charge </div>
          <h4>
            Craft Gold Jewellery With <br />
            Zero Making Charges!
          </h4>
          <button
            className="defult-btn-section-video "
            onClick={() =>
              window.open("http://store.luckystargold.com/", "_blank")
            }
          >
            Vist store
          </button>
        </div>
      </Container>
      <div className="Contact-Div">
        <img
          className=""
          src={Image}
          alt="overlay"
          style={{ width: "35px", height: "35px" }}
        />
      </div>
    </section>
  );
}
export default VideoSection;
