import React, { useEffect, useRef } from "react";
import "./style.scss";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Col, Container, Row } from "react-bootstrap";

gsap.registerPlugin(ScrollTrigger);

function Section3() {
  const imgRef = useRef(null);
  const textReff = useRef(null);
  const buttonRef = useRef(null);
  useEffect(() => {
    gsap.fromTo(
      imgRef.current,
      { y: 200, opacity: 0 },
      {
        y: 0,
        opacity: 1,
        ease: "power3.out",
        scrollTrigger: {
          trigger: imgRef.current,
          start: "top 100%",
          end: "top 20%",
          scrub: 2,
          markers: false,
        },
      }
    );
    gsap.fromTo(
      textReff.current,
      { scale: 2, opacity: 0 }, // Start with larger scale and hidden
      {
        scale: 1, // End with normal scale
        opacity: 1, // Fully visible
        scrollTrigger: {
          trigger: textReff.current,
          start: "top 70%", // Trigger animation when element is 80% from the top
          end: "top 20%", // End animation when element is 30% from the top
          scrub: true,
        },
        ease: "power3.out", // Animation easing
        duration: 1.5, // Duration of the animation
      }
    );

    gsap.fromTo(
      buttonRef.current,
      { x: "-100%", opacity: 0 }, // Start 30% to the left with opacity 0
      {
        x: "10%", // End at the original position
        opacity: 1, // Fully visible
        duration: 1.5, // Duration of the animation
        ease: "power3.out", // Smooth easing
        scrollTrigger: {
          trigger: buttonRef.current,
          start: "top 40%", // Trigger animation when element is 70% from the top
          end: "top 20%", // End when the top of the button is 50% from the top of the viewport
          scrub: 2, // Smooth animation synced with scroll
        },
      }
    );
  }, []);
  return (
    <section className="section-3">
      <div>
        <video
          loop
          muted
          autoPlay={true}
          playsInline
          className="section-3-Video"
        >
          <source src="/sec3bgvideo2.mp4" type="video/mp4" />
        </video>
        <div className="sectin3-div">
          <Container>
            <Row>
              <Col sm={0} xs={0} lg={6}>
                <div className="sectin3-div-Image">
                  <Row>
                    <Col lg={12}>
                      <img className="sectin3-Image" src="/invest1.jpg" />
                    </Col>
                    <Col lg={6}>
                      <img className="sectin3-Image2" src="/invest2.jpg" />
                    </Col>
                    <Col lg={6}>
                      <img className="sectin3-Image2" src="/invest3.jpg" />
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col lg={6}>
                <div className="Content-Txt1">
                  {" "}
                  Invest in Gold
                  <br /> Physically{" "}
                </div>
                <div className="Content-Txt2">
                  {" "}
                  Lets see the Gold Investment
                </div>
                <p style={{ fontSize: "14px" }}>
                  With our platform, you have the opportunity to purchase
                  digital gold that's directly backed by real, physical gold.
                  When you buy digital gold through our website, you're not just
                  completing a virtual transaction—you're securing ownership of
                  actual, physical gold that we safely store for you in trusted,
                  high-security vaults{" "}
                </p>

                <p>
                  {" "}
                  We understand how important it is to balance the convenience
                  of modern technology with the security of traditional assets.
                  That's why our service provides you with the best of both
                  worlds—enabling easy, hassle-free digital transactions while
                  giving you the peace of mind that comes with owning tangible
                  gold.
                </p>
                <p>
                  {" "}
                  Whether you're looking to trade your digital gold or
                  eventually convert it into physical gold bars or coins, we've
                  designed our platform to offer you maximum flexibility and
                  control over your investments. You can trust us to make
                  managing your gold simple, secure, and transparent.
                </p>
                <div className="invest-btn">
                  <button
                    className="defult-btn "
                    onClick={() =>
                      window.open(
                        "https://digigold.luckystargold.com/",
                        "_blank"
                      )
                    }
                  >
                    Investment In Gold
                  </button>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </section>
  );
}

export default Section3;
